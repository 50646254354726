import Grid, {Col} from '../../layouts/utils/Grid/Grid';
import Accordion, {type AccordionProps} from '../Accordion/Accordion';

export interface FaqProps {
  accordionItems: AccordionProps[];
  mode?: 'light' | 'dark';
  className?: string;
}

export default function Faq({
  accordionItems,
  mode = 'light',
  className = '',
}: FaqProps) {
  const componentName = 'faq';
  return (
    <Grid data-component-name={componentName} className={className}>
      <Col
        start={{xs: 1, sm: 2, md: 3, lg: 3, xl: 4}}
        span={{xs: 4, sm: 7, md: 10, lg: 9, xl: 8}}
        className="grid gap-y-xl"
      >
        {accordionItems.map((item) => (
          <Accordion
            heading={item.heading}
            content={item.content}
            id={item.id}
            mode={mode}
            key={item.id}
          />
        ))}
      </Col>
    </Grid>
  );
}
